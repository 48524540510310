import * as React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BlogPostTemplate = ({ data: { previous, next, site, markdownRemark: post } }: any) => {
    const siteTitle = site.siteMetadata?.title || `Title`;

    const featuredImg = getImage(
        post.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData,
    );

    return (
        <Layout pageTitle={siteTitle} activeKey={"/smart-home-products/"}>
            <article className="blog-post" itemScope itemType="https://schema.org/Product">
                <header>
                    <h1 itemProp="name">{post.frontmatter.title}</h1>
                    {featuredImg ? (
                        <GatsbyImage image={featuredImg} alt={post.frontmatter.title} loading={"eager"}  />
                    ) : null}
                    {post.frontmatter.category ? (
                        <p>
                            category:{" "}
                            <a
                                href={
                                    "/tag/" +
                                    post.frontmatter.category.replace(/ /g, "-").toLowerCase() +
                                    "/"
                                }
                            >
                                {post.frontmatter.category}
                            </a>
                        </p>
                    ) : null}
                    <p itemProp={"aggregateRating"} itemScope itemType={"https://schema.org/AggregateRating"}>
                        rate: <span itemProp={"ratingValue"}>{post.frontmatter.rate}</span> / 5 (<span itemProp={"ratingCount"}>{post.frontmatter.reviews}</span>)
                    </p>
                    <p>manufacturer: <a href={"/tag/"+(post.frontmatter.manufacturer.replace(/ /g, "-").toLowerCase())+"/"}>{post.frontmatter.manufacturer}</a></p>
                    <p>plugFormat: {post.frontmatter.plugFormat}</p>
                    <p>specification met: {post.frontmatter.specificationMet}</p>
                    <p>Amperage: {post.frontmatter.amperage}</p>
                    <p>UPC: {post.frontmatter.upc}</p>
                    <p>part number: {post.frontmatter.partNumber}</p>
                    <p>weight: {post.frontmatter.weight}</p>
                    <p>dimensions: {post.frontmatter.dimensions}</p>
                    <p>country of origin: {post.frontmatter.countryofOrigin}</p>
                    <p>model number: {post.frontmatter.modelNumber}</p>
                    <p>size: {post.frontmatter.size}</p>
                    <p>style: {post.frontmatter.style}</p>
                    <p>mounting type: {post.frontmatter.mountingType}</p>
                    <p>usage: {post.frontmatter.usage}</p>
                    <p>included components: {post.frontmatter.includedComponents}</p>
                    <p>batteries included: {post.frontmatter.batteriesIncluded}</p>
                    <p>batteries required: {post.frontmatter.batteriesRequired}</p>
                    <p>warranty description: {post.frontmatter.warrantyDescription}</p>
                </header>
                <p>
                    <a href={post.frontmatter.amazonUrl} rel={"nofollow"}>
                        Check on Amazon
                    </a>
                </p>
                <section dangerouslySetInnerHTML={{ __html: post.html }} itemProp="description" />
            </article>
            <nav className="blog-post-nav">
                <ul
                    style={{
                        display: `flex`,
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                        listStyle: `none`,
                        padding: 0,
                    }}
                >
                    <li>
                        {previous && (
                            <Link to={"/product" + previous.fields.slug} rel="prev">
                                ← {previous.frontmatter.title}
                            </Link>
                        )}
                    </li>
                    <li>
                        {next && (
                            <Link to={"/product" + next.fields.slug} rel="next">
                                {next.frontmatter.title} →
                            </Link>
                        )}
                    </li>
                </ul>
            </nav>
        </Layout>
    );
};

export const Head = ({ data: { markdownRemark: post } }: any) => {
    return (
        <Seo
            title={post.frontmatter.title}
            description={post.frontmatter.description || post.excerpt}
            url={"/product" + post.fields.slug}
        />
    );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
        site {
            siteMetadata {
                title
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                title
                category
                type
                url
                plugFormat
                specificationMet
                amperage
                upc
                manufacturer
                partNumber
                weight
                dimensions
                countryofOrigin
                modelNumber
                size
                style
                mountingType
                usage
                includedComponents
                batteriesIncluded
                batteriesRequired
                warrantyDescription
                rate
                reviews
                amazonUrl
                description
                featuredImage {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
            fields {
                slug
            }
        }
        previous: markdownRemark(id: { eq: $previousPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
        next: markdownRemark(id: { eq: $nextPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
    }
`;
